<template>
  <v-app-bar
    app
    ref="navbar"
    class="navbar-site white"
    clipped-left
    :height="$vuetify.breakpoint.smAndDown ? 109 : 91"
  >
    <div
      :class="$vuetify.breakpoint.smAndDown ? 'w-100 py-0' : 'mx-6 w-100 py-0'"
    >
      <v-row :align="'center'">
        <v-col
          cols="2"
          sm="1"
          md="1"
          lg="1"
          class="order-0 d-flex justify-start align-center"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
            :to="{ path: $routerHistory.previous().path }"
            elevation="0"
            aria-label="Torna Indietro"
          >
            <v-icon large elevation="0">$chevronLeft</v-icon>
          </v-btn>
          <v-btn
            plain
            :height="$vuetify.breakpoint.mdAndUp ? '70' : '40'"
            :width="$vuetify.breakpoint.mdAndUp ? '70' : '40'"
            elevation="0"
            tile
            aria-label="Prodotti"
            class="ml-3 ml-md-0"
            :small="!$vuetify.breakpoint.mdAndUp"
            @click.prevent.stop="
              $store.commit('custom/updateMenuPositionModel', 0);
              drawerLeft = !drawerLeft;
            "
          >
            <v-icon large class="btn_icon">$menu</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          sm="7"
          md="1"
          lg="2"
          :class="[
            { 'justify-start align-start': $vuetify.breakpoint.smAndDown },
            { 'justify-center align-center': $vuetify.breakpoint.mdAndUp }
          ]"
          class="order-1 d-flex"
        >
          <!-- logo desktop -->
          <router-link
            :to="{ name: 'Home' }"
            class="logo-link"
            aria-label="Torna alla homepage"
            title="Clicca per andare alla homepage"
          >
            <!-- logo -->
            <img
              id="logo-iper-shop"
              class="logo"
              :src="serviceImg"
              alt="Logo IperDrive"
            />
          </router-link>
          <!-- logo mobile -->
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          class="order-4 order-md-2 d-flex px-md-4 search-inpt-cnt"
        >
          <div class="flex-grow-1 flex-shrink-0 py-md-0">
            <SearchInput />
          </div>
        </v-col>
        <v-col
          cols="4"
          sm="4"
          md="6"
          lg="5"
          class="order-2 order-md-3 d-flex justify-end align-center"
        >
          <template v-if="!isAuthenticated">
            <v-btn
              @click.stop="handleLogin()"
              medium
              icon
              tile
              class="d-inline"
              :height="$vuetify.breakpoint.mdAndDown ? 50 : 40"
              :aria-label="$t('login.title')"
            >
              <v-icon :color="$vuetify.breakpoint.mdAndUp ? 'primary' : ''"
                >$profile</v-icon
              >
            </v-btn>
            <v-btn
              @click.stop="handleLogin()"
              medium
              text
              class="d-none d-sm-inline pl-0 font-weight-regular mr-2"
            >
              <span
                v-html="$t('navbar.signup')"
                class="ml-2 text-left text-none"
              ></span>
            </v-btn>
            <v-btn
              :height="$vuetify.breakpoint.mdAndDown ? 50 : 40"
              icon
              tile
              @click.stop="handleLogin()"
              class="mr-2"
              :aria-label="$t('bottom.cart')"
            >
              <v-icon :color="$vuetify.breakpoint.mdAndUp ? 'primary' : ''"
                >$cart</v-icon
              >
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="ml-2 d-flex d-md-none"
              @click.stop="handleAddressTimeslot()"
              large
              icon
            >
              <v-icon color="primary">$calendarPen</v-icon>
            </v-btn>
            <div class="d-none d-md-flex">
              <CartInfoCompact :isCart="false" class="infos" />
            </div>
            <!-- <div class="delivery-service-selector d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="openAddressSelector"
                    class="d-none d-md-inline service-button"
                    height="50"
                    alt="Selezione servizio"
                    aria-label="Selezione servizio"
                  >
                    <v-icon x-large class="service-icon">
                      {{
                        "$" +
                          $t(`navbar.service.${getDeliveryServiceId}.fontIcon`)
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span class="delivery-service-tooltip-content">
                  <strong
                    >{{ shippingAddress }}<br />
                    {{ timeslot }}</strong
                  >
                </span>
              </v-tooltip>
              <div class="nav-selectors d-none d-lg-inline">
                <v-btn
                  x-small
                  text
                  class="d-flex justify-space-between"
                  @click="openAddressSelector"
                >
                  <span class="info-text font-weight-semibold">{{
                    shippingAddress
                  }}</span
                  ><v-icon
                    v-if="editableAddress"
                    color="default"
                    x-small
                    class="ml-1"
                    >$edit</v-icon
                  >
                </v-btn>
                <v-btn
                  x-small
                  text
                  class="d-flex justify-space-between"
                  @click="openTimeslotSelector"
                >
                  <span class="info-text font-weight-regular text-lowercase">
                    {{ timeslot }} </span
                  ><v-icon
                    v-if="editableTimeslot"
                    color="default"
                    x-small
                    class="ml-1"
                    >$edit</v-icon
                  >
                </v-btn>
              </div>
            </div> -->

            <div class="d-flex flex-row align-center mr-2 text-caption">
              <v-menu class="ml-2" bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center">
                    <v-btn
                      class="ml-2"
                      v-bind="attrs"
                      v-on.stop="on"
                      large
                      icon
                    >
                      <v-icon color="primary">$profile</v-icon>
                    </v-btn>
                    <span
                      class="d-flex flex-column"
                      style="line-height: 1;"
                      v-html="
                        $t('widgets.homepageWidget.title', [
                          cart.user.firstName
                        ])
                      "
                    >
                    </span>
                  </div>
                </template>
                <UserMenu :user="user" />
              </v-menu>
              <transition
                name="custom-classes"
                enter-active-class="animate__animated animate__swing"
              >
                <v-badge
                  :content="totalItems"
                  :value="totalItems > 0"
                  :key="totalItemQuantity"
                  color="#E11C24"
                  overlap
                  :offset-x="$vuetify.breakpoint.mdAndDown ? 22 : 91"
                  offset-y="25"
                  class="d-flex flex-row align-center font-weight-semibold cart-badge"
                >
                  <v-btn
                    aria-label="Apri carrello"
                    medium
                    :icon="!$vuetify.breakpoint.lgAndUp"
                    depressed
                    tile
                    :color="
                      $vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'
                    "
                    @click.stop="openCart()"
                    class="cart-btn"
                    :height="$vuetify.breakpoint.mdAndDown ? 50 : 40"
                    :width="$vuetify.breakpoint.mdAndDown ? 50 : 110"
                  >
                    <v-icon color="primary">$cart</v-icon>
                    <div
                      class="d-none d-lg-inline-block align-left cart-total"
                      v-html="
                        `<span class='default--text ml-1'> ${$n(
                          cart.totalPrice,
                          'currency'
                        )} </span>`
                      "
                    ></div>
                  </v-btn>
                </v-badge>
              </transition>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
    <v-progress-linear
      aria-label="Caricamento in corso"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      :color="$vuetify.breakpoint.mdAndUp ? 'white' : 'primary'"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.cart-badge .v-badge__badge {
  border-radius: 4px !important;
  font-size: 9px !important;
  height: 14px !important;
  min-width: 14px !important;
  padding: 3px !important;
  inset: auto auto 26px 32px !important;
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";
// import Login from "@/components/Login.vue";
import CartInfoCompact from "../cart/CartInfoCompact.vue";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";

// import get from "lodash/get";
import { mapActions, mapState, mapGetters } from "vuex";
// import GiftMenuButton from "../gift/GiftMenuButton.vue";

export default {
  name: "Navbar",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    UserMenu,
    CartInfoCompact
  },
  props: {
    primaryLinks: { type: Array, required: false },
    navbarItems: { type: Object, default: () => {} },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      bannerTopKey: 0,
      hidePendingOrders: false
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    async handleAddressTimeslot() {
      //await this.openAddressSelector();
      // await this.openTimeslotSelector();
      if (this.editableAddress) {
        if (await this.setAddress()) {
          if (this.editableTimeslot) {
            await this.setTimeslot();
          }
        }
      }
    },
    closeBannerNews() {
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = {};
          bannersTopShowingDate[headerNews.categoryBlockId] = new Date(
            Date.now() + 3600 * 1000 * 24
          ).toISOString();
          window.sessionStorage["bannersTopShowingDate"] = JSON.stringify(
            bannersTopShowingDate
          );
        }
      }
      this.bannerTopKey++;
    },
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs) {
            this.$router.push({ name: "Checkout" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    openDashboard() {
      if (this.isAuthenticated) {
        this.$router.push("/profile/dashboard");
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    totalItemQuantity() {
      return this.cart.cartItems?.reduce((acc, item) => {
        return acc + item.quantity + item.weight;
      }, 0);
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    alreadyShowedNewsBanner() {
      this.bannerTopKey;
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = window.sessionStorage[
            "bannersTopShowingDate"
          ]
            ? JSON.parse(window.sessionStorage["bannersTopShowingDate"])
            : {};
          let catBlockId = headerNews.categoryBlockId;

          let nextOpeningDate = bannersTopShowingDate[catBlockId];
          let today = new Date();
          if (
            Object.prototype.hasOwnProperty.call(
              bannersTopShowingDate,
              catBlockId
            ) &&
            Date.parse(nextOpeningDate) > Date.parse(today)
          ) {
            // there is banner but close already clicked
            return true;
          }

          // show banner
          return false;
        } else {
          // there is no proposal -> do not show news banner
          return true;
        }
      }
      // there is no banner to show
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.primary-links {
  height: 36px;
}
@media (max-width: 365px) {
  .navbar-site {
    .profile-btn {
      display: none;
    }
  }
}
.navbar-site {
  .order-0,
  .order-1,
  .order-2,
  .order-3 {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 0px;
    }
  }
}
.search-inpt-cnt {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 0 12px;
  }
}

.navbar-site {
  ::v-deep .v-toolbar__content {
    border-bottom: 5px solid $primary !important;
  }
}

.uncompleted-orders {
  z-index: 14;
  position: absolute;
  font-weight: 600;
}
.shake-enter-active {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
    opacity: 0.8;
  }
  50% {
    transform: translateX(-4px);
    opacity: 0.8;
  }
  75% {
    transform: translateX(2px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 0.5;
  }
}
.navbar {
  background-color: $white;
  //height: 80px;
  border-bottom: 5px solid var(--v-primary-base);
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    //height: 112px;
    box-shadow: 0px 2px 13pt #0000004d;
  }
  .menu_btn {
    ::v-deep .v-btn__content {
      flex-direction: column;
      .btn_label {
        font-size: 10px;
      }
    }
  }
}
.welcome {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: var(--v-secondary-base);
  text-transform: capitalize;
}

.logo-link {
  .logo {
    // width: 200px;
    height: 60px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      // width: 133px;
      height: 40px;
    }
  }
}
.navigation-selector-container {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  background-color: var(--v-grey-base);
}

::v-deep .v-toolbar__extension {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
}
.nav-selectors {
  .info-text {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nav-buttons {
  .v-btn {
    padding: 0px 4px;
  }
  .service-icon {
    vertical-align: unset;
  }
  .points {
    img {
      width: 28px;
    }
  }
}
</style>
