<template>
  <v-list class="user-menu rounded-md pa-2" min-width="230">
    <v-list-item :to="{ name: 'Dashboard' }">
      <v-list-item-title>{{ $t("profile.menu.dashboard") }}</v-list-item-title>
    </v-list-item>
    <v-divider />

    <!-- <v-list-item :to="{ name: 'ProfileUpdate' }">
      <v-list-item-title>{{ $t("profile.menu.profile") }}</v-list-item-title>
    </v-list-item>
    <v-divider /> -->

    <v-list-item :to="{ name: 'Orders' }">
      <v-list-item-title>{{ $t("profile.menu.orders") }}</v-list-item-title>
    </v-list-item>

    <!-- <v-list-item :to="{ name: 'LastPurchase' }">
          <v-list-item-title>{{
            $t("profile.menu.lastPurchase")
          }}</v-list-item-title>
        </v-list-item> -->
    <v-divider />

    <v-list-item :to="{ name: 'Lists' }">
      <v-list-item-title>{{ $t("profile.menu.lists") }}</v-list-item-title>
    </v-list-item>
    <v-divider />

    <v-list-item :to="{ name: 'Addresses' }">
      <v-list-item-title>{{ $t("profile.menu.addresses") }}</v-list-item-title>
    </v-list-item>
    <v-divider />

    <v-list-item :to="{ name: 'MyPaymentMethods' }">
      <v-list-item-title>{{ $t("profile.menu.cards") }}</v-list-item-title>
    </v-list-item>
    <!-- <v-list-item :to="{ name: 'Invoice' }">
          <v-list-item-title>{{
            $t("profile.menu.invoice")
          }}</v-list-item-title>
        </v-list-item> -->
    <v-divider />

    <v-list-item to="/vantaggi-piu">
      <v-list-item-title>{{
        $t("profile.menu.advantagesPlus")
      }}</v-list-item-title>
    </v-list-item>

    <!-- <v-list-item :to="{ name: 'PaymentDueDate' }">
          <v-list-item-title>{{
            $t("profile.menu.paymentDueDate")
          }}</v-list-item-title>
        </v-list-item> -->
    <v-divider />

    <v-list-item @click.stop="onLogoutClick">
      <v-list-item-title
        ><v-icon color="primary" class="mr-1">$doorOpen</v-icon>
        {{ $t("profile.menu.logout") }}</v-list-item-title
      >
    </v-list-item>

    <!-- <v-col cols="6">
        <v-list-item :to="{ name: 'RewardsCart' }">
          <v-list-item-title>{{
            $t("profile.menu.rewardsCart")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'RequestedRewards' }">
          <v-list-item-title>{{
            $t("profile.menu.requestedRewards")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'ProfileGift' }">
          <v-list-item-title>{{ $t("profile.menu.gifts") }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'CreateOffer' }">
          <v-list-item-title>{{
            $t("profile.menu.createOffer")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'Donate' }">
          <v-list-item-title>{{ $t("profile.menu.donate") }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Invoice' }">
          <v-list-item-title>{{
            $t("profile.menu.invoice")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>&nbsp;</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="onLogoutClick">
          <v-list-item-title>{{ $t("profile.menu.logout") }}</v-list-item-title>
        </v-list-item>
      </v-col> -->
  </v-list>
</template>
<style scoped lang="scss">
.user-menu {
  .v-list-item--active {
    &::before {
      opacity: 0 !important;
    }
    .v-list-item__title {
      font-weight: bold;
      color: $primary;
    }
  }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    user: { type: Object, required: true }
  },
  data() {
    return {
      coudlBecomeTco: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getLegalById: "cart/getLegalById"
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async onLogoutClick() {
      let isLoggedOut = await this.doLogout();
      if (isLoggedOut) {
        window.gigya.accounts.logout(function() {}, null, true);
        if (this.$route.name !== "Home") {
          this.$router.push({ name: "Home" });
        }
      }
    }
  }
};
</script>
